import React from 'react';
/// Components
import Layout from '../components/layout';
import SubscribeForm from '../components/subscribe-form';
import Seo from '../components/seo';

const GCAForm = () => (
    <Layout>
        <Seo title='Subscribe Form' />
        <SubscribeForm
            source='gca-website'
            title='Gold Coast Airport Newsletter'
            subtitle='Subscribe to receive the latest offers and promotions from Gold Coast Airport'
        />
    </Layout>
);

export default GCAForm;
